@import '../../scss/mixin.scss';
@import '../../scss/variables.scss';


.header-image {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;

    position: relative;

    &__body {
        width: 100%;
        max-width: 3000px;
        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0, .1);
    }

    &__box-img {
        width: 100%;
        height: 100%;
        aspect-ratio: 100 / 23;
        @media (max-width: 1920px) {
            aspect-ratio: 100 / 25;
        }
        @media (max-width: 767px) {
            aspect-ratio: 100 / 130;
        }
    }

    &__img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    
}

.hello {
    position: absolute;
    bottom: 10%;
    left: 0;
    width: 100%;
    z-index: 3;

    // background-color: rgba(0,0,0, .35);
    background-color: rgba(0, 0, 0, .35);

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 10px;

    @media (max-width: 1200px) {
        bottom: 0;
    }

    @media (max-width: 1000px) {
        height: 100%;
        align-items: flex-end;
    }

    &__body {
        padding: 10px 0 10px 0;

        width: 100%;
        max-width: 1400px;

        font-family: 'Mont';
        text-align: center;
    }

    &__hi {
        color: #fff;
        font-weight: 700;
        margin-bottom: 10px;
        letter-spacing: 2px;
    }

    &__I {
        color: #fff;
        letter-spacing: 3px;
        font-weight: 700;
        line-height: 130%;
    }

    &__text {
        color: #fff;
        margin-top: 10px;
        line-height: 130%;
        font-weight: 500;
        letter-spacing: 1px;
        white-space: pre-line;

        @media (max-width: 767px) {
            line-height: 150%;
        }
    }

    &__show {
        color: #fff;
        margin-top: 10px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 130%;
    }
}

.info-test {

}