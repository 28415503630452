@import '../../scss/variables.scss';

.gallery {
    display: flex;
    justify-content: center;
    column-gap: $gap;
    padding: 0 $gap 0 $gap;
    width: 100%;

    @media (max-width: 749px){
        column-gap: $gap-mobile;
        padding: 0;
    }

    // .gallery__column
    &__column {
        flex: 0 1 25%;
        display: flex;
        flex-direction: column;
        row-gap: $gap;

        @media (max-width: 749px){
            flex: 0 1 100%;
            row-gap: $gap-mobile;
        }

        & img{
            object-fit: cover;
            width: 100%;
            height: 100%;
            pointer-events:none;
        }
    }
}

.anime{
    animation: fade infinite 2s;
}

@keyframes fade {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

.loading-img{
    animation: loading-img 1s;
}

@keyframes loading-img {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}